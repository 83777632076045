<template>
	<header class="navbar navbar-expand-lg">
		<ul class="navbar-group" role="menubar" aria-label="User menu">
			<li v-if="isScheduleShowRoute" class="nav-item mr-auto" role="none">
				<h1 class="nav-calendar-title">{{ calendar }}</h1>
			</li>
			<li class="nav-item mr-auto" role="none">
				<form
					v-if="$route.meta.search"
					class="flex"
					@submit.prevent="onSearch"
				>
					<div class="flex relative">
						<input
							v-model="search"
							type="text"
							name="search"
							placeholder="Search..."
							autocomplete="off"
							aria-label="Search through site content"
							class="shadow appearance-none w-full py-1 px-3 my-1 leading-tight focus:shadow-outline bg-transparent border text-gray-700 rounded border-gray-500 focus:outline-none font-roboto placeholder-gray-600"
							@keyup.esc="search = ''"
						/>
						<font-awesome-icon
							v-if="busy"
							spin
							:icon="['fal', 'circle-notch']"
							class="search-clear-btn"
						/>
						<font-awesome-icon
							v-else-if="hasSearchTerm"
							:icon="['fal', 'times']"
							class="search-clear-btn"
							@click.prevent="search = ''"
						/>
					</div>

					<button
						type="submit"
						class="btn-icon-button nav-link flex flex-col ml-2"
						role="menuitem"
					>
						<span class="sr-only">Search</span>
						<font-awesome-icon :icon="['fal', 'search']" />
					</button>
				</form>
			</li>
			<li class="nav-item d-none" role="none">
				<button type="button" class="btn-icon-button">
					<span class="sr-only">Messages</span>
					<font-awesome-icon :icon="['fal', 'envelope']" />
				</button>
			</li>
			<notifications-dropdown />
			<account-dropdown />
		</ul>
	</header>
</template>
<script>
import { mapGetters } from 'vuex'
import AccountDropdown from './AccountDropdown'
import NotificationsDropdown from './NotificationsDropdown'
import get from 'lodash/get'

export default {
	/**
	 * The component's registered child components.
	 *
	 * @type {Object}
	 */
	components: {
		AccountDropdown,
		NotificationsDropdown,
	},

	/**
	 * The component's computed properties.
	 *
	 * @type {Object}
	 */
	computed: {
		/**
		 * Get the current route's calendar name.
		 *
		 * @return {?String}
		 */
		calendar() {
			if (!this.isScheduleShowRoute) {
				return null
			}

			const calendar = this.findCalendar(this.$route.params.id)

			return calendar?.name
		},

		/**
		 * Determine if a search term exists.
		 *
		 * @return {Boolean}
		 */
		hasSearchTerm() {
			return this.search.length > 0
		},

		/**
		 * Determine if the current route is the schedule show route.
		 *
		 * @return {Boolean}
		 */
		isScheduleShowRoute() {
			return (
				this.$route.name === 'app.schedules.show' &&
				this.$route.params.id
			)
		},

		...mapGetters({
			findCalendar: 'calendars/find',
		}),
	},

	/**
	 * The component's local methods.
	 *
	 * @type {Object}
	 */
	methods: {
		/**
		 * Handle the on route change event.
		 * @return {void}
		 */
		async onRouteChange(to, from) {
			const module = get(from, 'meta.search.0', null)
			module && this.$store.dispatch(`${module}/removeSearchFilter`)
			this.onRemoveSearch()
		},

		/**
		 * Get the modules to search from the current route meta.
		 *
		 * @return {Array}
		 */
		getSearchModules() {
			return this.$route.meta.search || []
		},

		/**
		 * Apply the filters and load the provided modules.
		 *
		 * @return {void}
		 */
		async fetch() {
			this.getSearchModules().map(module => {
				if (
					(module === 'calls' || module === 'pages') &&
					this.$route.params.sid
				) {
					let payload = null
					payload = {
						fromFilters: true,
						callId: this.$route.params.sid,
					}
					return this.$store.dispatch(`${module}/get`, payload)
				}
				return this.$store.dispatch(`${module}/get`)
			})
		},

		/**
		 * Handle the search click event.
		 *
		 * @return {void}
		 */
		async onSearch() {
			if (
				this.busy ||
				this.getSearchModules().length === 0 ||
				this.search.length === 0
			) {
				return
			}

			this.busy = true

			this.searching = true

			await this.setSearchFilter()

			await this.fetch()

			setTimeout(() => (this.busy = false), 250)
		},

		/**
		 * Remove search from module.
		 *
		 * @return {void}
		 */
		async onRemoveSearch() {
			this.search = ''

			await this.getSearchModules().map(module => {
				return this.$store.dispatch(`${module}/removeSearchFilter`)
			})

			this.searching = false
		},

		/**
		 * Set the search filter on the provided store's module.
		 *
		 * @return {void}
		 */
		async setSearchFilter() {
			return await this.getSearchModules().map(module => {
				return this.$store.dispatch(`${module}/setFilters`, {
					search: this.search,
				})
			})
		},
	},

	/**
	 * The component's name used for debugging.
	 *
	 * @type {String}
	 */
	name: 'Navbar',

	/**
	 * The component's property watchers.
	 *
	 * @type {Object}
	 */
	watch: {
		/**
		 * Watch the current route for changes. //
		 */
		$route: {
			immediate: true,
			handler: 'onRouteChange',
		},

		/**
		 * Watch the search term exists computed property.
		 *
		 * @return {void}
		 */
		hasSearchTerm() {
			if (this.searching && !this.hasSearchTerm) {
				this.onRemoveSearch()
			}
		},
	},

	/**
	 * Get the component's initial state.
	 *
	 * @return {Object}
	 */
	data() {
		return {
			busy: false,
			search: '',
			searching: false,
		}
	},
}
</script>
